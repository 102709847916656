import { Flex, Button, Text } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import Map from 'components/shared/Inputs/Map';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

const LocationDetailsForm = ({
  defaultValues = {},
  onSubmit,
  isLoading,
  isUpdate,
  hideCity,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues,
  });

  const watchAddress = watch('address');

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Controller
        control={control}
        name="address"
        rules={{
          validate: (value, formValues) => {
            if (isUpdate) {
              return true;
            }

            return (
              !!(value?.city_name && value?.country_name) ||
              'thisFieldIsRequired'
            );
          },
        }}
        render={({ field: { onChange, value } }) => (
          <ErrorBoundary fallback={<Text>{t('somethingWentWrong')}</Text>}>
            <Map
              label={t('address')}
              onAddressChange={(address) => {
                onChange({
                  city_name: address.city?.long_name,
                  country_name: address.country?.long_name,
                  location: address.location,
                  name: address.name,
                  url: address.url,
                });
              }}
              defaultValue={defaultValues.address}
              error={t(errors.address?.message)}
              inputSize="lg"
              value={value}
              disabled={isUpdate}
              hideSearch={isUpdate}
              watchAddress={watchAddress}
              mapHeight="390px"
              showCity={!hideCity}
              isFlipped
              required
            />
          </ErrorBoundary>
        )}
        defaultValue={defaultValues.address}
      />

      {!isUpdate && (
        <Flex justifyContent="flex-end">
          <Button
            colorScheme="primary"
            type="submit"
            size="lg"
            mt={8}
            textTransform="capitalize"
            isLoading={isLoading}
          >
            {t('saveChanges')}
          </Button>
        </Flex>
      )}
    </form>
  );
};

export default LocationDetailsForm;
