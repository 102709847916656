import {
  Button,
  Flex,
  Stack,
  Text,
  Tabs,
  TabList,
  Tab as ChakraTab,
  HStack,
  useDisclosure,
  FormLabel,
  Box,
  SimpleGrid,
  Center,
  StackDivider,
  Divider,
  Card,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  List,
  ListItem,
  ListIcon,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useMediaQuery,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as WarehouseIcon } from 'assets/icons/warehouse.svg';
import { ReactComponent as QuotationsIcon } from 'assets/icons/bill.svg';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import PageDrawer from 'components/Dashboard/PageDrawer';
import { Controller, useForm } from 'react-hook-form';
import { getCities, initQuote, requestQuote } from 'api/onboarding';
import Select from 'components/Dashboard/Select';
import { useMutation, useQuery } from 'react-query';
import useLanguage from 'hooks/useLanguage';
import { agreementTypeEnum } from 'constants/dashboard';
import RadioCardGroup from 'components/shared/Inputs/RadioCardGroup/RadioCardGroup';
import StepperInput from 'components/shared/Inputs/StepperInput';
import {
  addDayToDate,
  adjustToUTC,
  formatDate,
  utcStringToDate,
} from 'utils/date';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { useEffect, useState } from 'react';
import BackToListButton from 'components/Dashboard/BackToListButton';
import StatusTag from 'components/Dashboard/StatusTag';
import { storageTypes } from './Warehouses';
import Carousel from 'components/shared/Carousel';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';
import { ReactComponent as TagsIcon } from 'assets/icons/tags.svg';
import { ReactComponent as CheckCircle } from 'assets/icons/checkCircle.svg';
import { ReactComponent as CloseCircle } from 'assets/icons/closeCircle.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { createColumnHelper } from '@tanstack/react-table';
import TablePage from 'components/Dashboard/TablePage';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import { getFormSelectDefaultValue } from 'utils/select';
import { queryClient } from 'utils/queryClient';
import { ReactComponent as PalletizedInfo } from 'assets/images/palletized-info.svg';
import { ReactComponent as BulkyGoodsInfo } from 'assets/images/bulky-goods-info.svg';

const columnHelper = createColumnHelper();

const WhiteQuotationsIcon = styled(QuotationsIcon)`
  path {
    stroke: #fff;
  }
`;

const StyledLocationIcon = styled(LocationIcon)`
  path {
    fill: #fff;
    stroke: #4a5568;
  }
`;

const InventoryTypeLabel = ({ label, description = [], illustration }) => {
  return (
    <Flex width="full" paddingBlock={4} paddingInline={2} alignItems="center">
      <Stack flex={1} spacing={2}>
        <Text fontSize="lg" fontWeight={600} textTransform="capitalize">
          {label}
        </Text>
        {description.map(({ title, content }, index) => (
          <Text color="gray.900" fontSize="sm" fontWeight={400} key={index}>
            <Text as="span" fontWeight={600} marginInlineEnd={1}>
              {title}:
            </Text>
            {content}
          </Text>
        ))}
      </Stack>

      <Box maxWidth="200px">{illustration}</Box>
    </Flex>
  );
};
const getQuantityType = (warehousesSearch) =>
  !!+warehousesSearch.number_of_pallets ? 'pallet' : 'sqM';

const QuoteForm = ({ onSubmit, backendErrors, defaultValues = {} }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAR = language === 'ar';

  const { data: citiesData, isLoading } = useQuery('quote-cities', getCities);

  const formDefaultValues = {
    ...defaultValues,
    date: utcStringToDate(defaultValues.date),
    ending_date: utcStringToDate(defaultValues.ending_date),
  };

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
    setValue,
    trigger,
  } = useForm({
    defaultValues: formDefaultValues,
  });

  const watchInventoryType = watch('inventory_type');
  const watchAgreementType = watch('type_of_agreement');
  const watchStartDate = watch('date');
  const watchEndDate = watch('ending_date');

  const showPalletsInput =
    watchInventoryType === 'ready_to_palletize' ||
    watchInventoryType === 'palletized';

  const showSpaceInput = watchInventoryType === 'bulky_goods';

  useEffect(() => {
    if (
      watchInventoryType === 'ready_to_palletize' ||
      watchInventoryType === 'palletized'
    ) {
      setValue('number_of_sqm', '0');
      return;
    }

    setValue('number_of_pallets', '0');
  }, [watchInventoryType, setValue]);

  const cityOptions = citiesData?.map(({ id, name, nameLocale }) => ({
    value: id,
    label: isAR ? nameLocale : name,
  }));

  const sfdaLicenseOptions = [
    { label: t('noSFDALicense'), value: undefined },
    { label: t('warehouseLicenses.sfda_food'), value: 'sfda_food' },
    { label: t('warehouseLicenses.sfda_pharma'), value: 'sfda_pharma' },
    { label: t('warehouseLicenses.sfda_medical'), value: 'sfda_medical' },
    { label: t('warehouseLicenses.sfda_pet_food'), value: 'sfda_pet_food' },
    { label: t('warehouseLicenses.sfda_cosmetics'), value: 'sfda_cosmetics' },
    { label: t('warehouseLicenses.sfda_pesticides'), value: 'sfda_pesticides' },
  ];

  const inventoryTypeOptions = [
    {
      label: (
        <InventoryTypeLabel
          label={t('pallets')}
          illustration={<PalletizedInfo />}
          description={[
            {
              title: t('pallet'),
              content: t('inventoryTypeInfo.palletContent'),
            },
            {
              title: t('inventoryTypeInfo.dimensionsTitle'),
              content: t('inventoryTypeInfo.dimensionsContent'),
            },
            {
              title: t('inventoryTypeInfo.idealTitle'),
              content: t('inventoryTypeInfo.idealContent'),
            },
          ]}
        />
      ),
      value: 'palletized',
    },
    {
      label: (
        <InventoryTypeLabel
          label={t('freeSpace')}
          illustration={<BulkyGoodsInfo />}
          description={[
            {
              title: t('inventoryTypeInfo.floorAreaTitle'),
              content: t('inventoryTypeInfo.floorAreaContent'),
            },
          ]}
        />
      ),
      value: 'bulky_goods',
    },
  ];

  const agreementTypeOptions = Object.keys(agreementTypeEnum).map((key) => ({
    label: t(`agreementType.${key}`),
    value: agreementTypeEnum[key],
  }));

  const warehouseTypeOptions = [
    {
      label: t('dry'),
      value: 'dry',
    },
    {
      label: t('tempControlled'),
      value: 'air_conditioned',
    },
    {
      label: t('chilled'),
      value: 'refrigerated',
    },
    {
      label: t('frozen'),
      value: 'frozen',
    },
  ];

  if (isLoading) {
    return <Spinner color="primary.500" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="quoteForm" noValidate>
      <Stack spacing={6}>
        <Controller
          control={control}
          name="city_id"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field: { onChange } }) => (
            <Select
              label={t('city')}
              placeholder={t('selectCity')}
              options={cityOptions}
              onChange={({ value }) => onChange(value)}
              error={errors?.city_id}
              defaultValue={getFormSelectDefaultValue(
                formDefaultValues.city_id,
                cityOptions
              )}
              required
            />
          )}
        />

        <Controller
          control={control}
          name="warehouse_type"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field: { onChange } }) => (
            <Select
              label={t('warehouseType')}
              placeholder={t('selectWarehouseType')}
              options={warehouseTypeOptions}
              onChange={({ value }) => onChange(value)}
              error={errors?.warehouse_type}
              defaultValue={getFormSelectDefaultValue(
                formDefaultValues.warehouse_type,
                warehouseTypeOptions
              )}
              required
            />
          )}
        />
        <Controller
          control={control}
          name="sfda_license_type"
          render={({ field: { onChange } }) => (
            <Select
              label={t('sfdaLicensing')}
              placeholder={t('requiredLicensing')}
              options={sfdaLicenseOptions}
              onChange={({ value }) => onChange(value)}
              error={errors?.sfda_license_type}
              defaultValue={getFormSelectDefaultValue(
                formDefaultValues.sfda_license_type,
                sfdaLicenseOptions
              )}
            />
          )}
        />

        <RadioCardGroup
          name="inventory_type"
          label={t('storageType')}
          options={inventoryTypeOptions}
          register={register}
          required="thisFieldIsRequired"
          errors={errors}
          defaultValue={formDefaultValues.inventory_type}
          columns={1}
          withCheckMark
        />

        {watchInventoryType && (
          <Box spacing={4}>
            <FormLabel>
              {t(
                showPalletsInput
                  ? 'howManyPalletPositionsDoYouExpectToReserve'
                  : 'howMuchFreeSpaceInSqmDoYouExpectToReserve'
              )}
            </FormLabel>

            {showPalletsInput && (
              <StepperInput
                name="number_of_pallets"
                label={t('pallets')}
                register={register}
                required="thisFieldIsRequired"
                min={10}
                minErrorMessage="minIs"
                setValue={setValue}
                trigger={trigger}
                errors={errors}
                defaultValue={formDefaultValues.number_of_pallets}
              />
            )}

            {showSpaceInput && (
              <StepperInput
                name="number_of_sqm"
                label={t('sqM')}
                register={register}
                required="thisFieldIsRequired"
                min={10}
                minErrorMessage="minIs"
                setValue={setValue}
                trigger={trigger}
                errors={errors}
                defaultValue={formDefaultValues.number_of_sqm}
              />
            )}
          </Box>
        )}

        <Controller
          control={control}
          name="type_of_agreement"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field: { onChange, value } }) => (
            <Select
              label={t('duration')}
              placeholder={t('selectDuration')}
              options={agreementTypeOptions}
              onChange={({ value }) => onChange(value)}
              error={errors?.type_of_agreement}
              defaultValue={getFormSelectDefaultValue(
                formDefaultValues.type_of_agreement,
                agreementTypeOptions
              )}
              required
            />
          )}
        />

        <SimpleGrid columns={2} spacing={4}>
          <DatePicker
            name="date"
            label={t('onboarding.startingDate')}
            register={register}
            setValue={setValue}
            trigger={trigger}
            errors={errors}
            required="thisFieldIsRequired"
            minDate={new Date().setHours(0, 0, 0, 0)}
            maxDate={watchEndDate}
            formValue={watchStartDate}
            control={control}
          />

          {watchAgreementType === agreementTypeEnum.specific_duration && (
            <DatePicker
              name="ending_date"
              label={t('endingDate')}
              register={register}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
              required={'thisFieldIsRequired'}
              minDate={addDayToDate(watchStartDate) || addDayToDate(new Date())}
              formValue={watchEndDate}
              control={control}
            />
          )}
        </SimpleGrid>
      </Stack>

      <Text color="red.500" fontWeight="500" fontSize="sm">
        {backendErrors}
      </Text>
    </form>
  );
};

const WarehouseSearchSection = ({ label, value }) => {
  return (
    <Center flexFlow="column">
      <Text
        sx={{
          color: 'gray.600',
          fontWeight: 500,
          fontSize: 'sm',
          textTransform: 'capitalize',
        }}
      >
        {label}
      </Text>
      <Text
        sx={{
          color: 'gray.900',
          fontWeight: 600,
          textTransform: 'capitalize',
        }}
      >
        {value}
      </Text>
    </Center>
  );
};

const ServicesModal = ({ isOpen, onClose, warehouse }) => {
  const { t } = useTranslation();

  const { alias_name, name, services_highlight } = warehouse;
  const servicesHighlight = Object.keys(services_highlight ?? {}).map((key) => {
    const value = services_highlight[key];
    const isNumberValue = typeof value === 'number';

    return {
      content: t(`servicesHighlight.${key}`, { value }),
      isChecked: isNumberValue ? true : value,
    };
  });
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingInlineEnd={8}>{alias_name ?? name}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <List spacing={3}>
            {servicesHighlight.map(({ content, isChecked }, index) => (
              <ListItem key={index} display="flex" alignItems="center">
                <ListIcon
                  as={isChecked ? CheckCircle : CloseCircle}
                  boxSize={8}
                />
                {content}
              </ListItem>
            ))}
          </List>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>{t('close')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SuggestedWarehousesDesktopItem = ({
  warehouse,
  warehousesSearch = {},
  onQuoteInit,
  isInitQuoteLoading,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAR = language === 'ar';
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    name,
    alias_name,
    address,
    condition_types,
    open_time,
    close_time,
    closed_days,
    starting_price,
    services_highlight,
    image_urls,
  } = warehouse;

  const closeDaysTranslated = closed_days.map((day) => t(`weekDays.${day}`));

  const closedTime = `${open_time} - ${close_time} ${t(
    'except'
  )} ${closeDaysTranslated.join(', ')}`;

  const quantityType = getQuantityType(warehousesSearch);

  const licenseType = warehousesSearch.sfda_license_type;
  const typeOfAgreement = Object.keys(agreementTypeEnum).find(
    (key) => agreementTypeEnum[key] === warehousesSearch.type_of_agreement
  );

  const handleInitQuote = () => {
    onQuoteInit(warehouse.id);
  };

  const conditionTypes = [...new Set(condition_types)];

  return (
    <Card
      padding={6}
      borderRadius="xl"
      flexDirection="row"
      gap={7}
      bg="gray.50"
      border="2px solid"
      borderColor="gray.200"
    >
      <Box
        borderRadius="8px"
        overflow="hidden"
        style={{
          width: '320px',
          height: '190px',
        }}
      >
        <Carousel
          slides={image_urls}
          imageProps={{
            height: '190px',
            width: '320px',
          }}
        />
      </Box>
      <Box flex={1}>
        <Box>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom={4}
            gap={4}
          >
            <Text fontWeight={700} fontSize="lg" color="gray.800">
              {alias_name ?? name}
            </Text>
            <Flex gap={2}>
              <Button
                variant="outline"
                bg="white"
                textTransform="capitalize"
                fontWeight={600}
                onClick={onOpen}
              >
                {t('viewServices')}
              </Button>

              <Button
                colorScheme="primary"
                textTransform="capitalize"
                fontWeight={600}
                onClick={handleInitQuote}
                isDisabled={isInitQuoteLoading}
              >
                {t('requestQuote')}
              </Button>
            </Flex>
          </Flex>
        </Box>

        <Flex fontSize="sm" fontWeight={500} color="gray.700" gap={8}>
          <Stack spacing={3} minWidth="250px">
            <HStack alignItems="center" spacing={2}>
              <StyledLocationIcon width="24px" height="24px" />
              <Text>{isAR ? address.city.nameLocale : address.city.name}</Text>
            </HStack>

            <HStack alignItems="center" spacing={2}>
              <ClockIcon width="24px" height="24px" />
              <Text as="span">{closedTime}</Text>
            </HStack>

            <HStack alignItems="center" spacing={2}>
              <TagsIcon width="24px" height="24px" />
              <Text as="span">
                {`${t('maxSKUsPerPallet')}: ${
                  services_highlight.max_sku_pallet
                } ${t('SKU')}`}
              </Text>
            </HStack>
          </Stack>

          <Divider
            width="2px"
            orientation="horizontal"
            height="auto"
            bg="gray.300"
            borderRadius="md"
          />

          <Stack spacing={3}>
            <HStack spacing={4}>
              {conditionTypes.map((type, index) => {
                const storageType = storageTypes[type];
                return (
                  <HStack key={index} spacing={2}>
                    <Text as="span">{storageType.icon}</Text>
                    <Text as="span">{t(storageType.label)}</Text>
                  </HStack>
                );
              })}
            </HStack>

            <HStack alignItems="center" spacing={2}>
              <Box>
                <DollarCircleIcon width="24px" height="24px" />
              </Box>
              <Text as="span">{`${t('startingAt')}: `}</Text>
              <Text as="span" fontWeight={600} color="gray.800">
                {`${(starting_price ?? 0) / 100} ${t('SAR')} / ${t(
                  quantityType
                )}`}
              </Text>
            </HStack>

            <HStack alignItems="center" spacing={3}>
              {typeOfAgreement && (
                <Box>
                  <StatusTag colorScheme="blue" size="sm">
                    {t(`agreementType.${typeOfAgreement}`)}
                  </StatusTag>
                </Box>
              )}

              {licenseType && (
                <Box>
                  <StatusTag colorScheme="green" size="sm">
                    {t(`warehouseLicenses.${licenseType}`)}
                  </StatusTag>
                </Box>
              )}

              {warehousesSearch.auto_renewal && (
                <Box>
                  <StatusTag colorScheme="purple" size="sm">
                    {t('autoRenewal')}
                  </StatusTag>
                </Box>
              )}
            </HStack>
          </Stack>
        </Flex>
      </Box>
      <ServicesModal isOpen={isOpen} onClose={onClose} warehouse={warehouse} />
    </Card>
  );
};

const ActionsMenu = ({ warehouse, onQuoteInit, isInitQuoteLoading }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Menu size="lg">
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<DotsVerticalIcon />}
          variant="solid"
          size="sm"
          isDisabled={isInitQuoteLoading}
        />
        <MenuList>
          <MenuItem onClick={onOpen} textTransform="capitalize">
            {t('viewServices')}
          </MenuItem>
          <MenuItem
            onClick={() => onQuoteInit(warehouse.id)}
            textTransform="capitalize"
          >
            {t('requestQuote')}
          </MenuItem>
        </MenuList>
      </Menu>

      <ServicesModal isOpen={isOpen} onClose={onClose} warehouse={warehouse} />
    </>
  );
};

const SuggestedWarehousesMobile = ({
  warehouseList,
  warehousesSearch = {},
  onQuoteInit,
  isInitQuoteLoading,
}) => {
  const { t } = useTranslation();

  const quantityType = getQuantityType(warehousesSearch);

  const typeOfAgreement = Object.keys(agreementTypeEnum).find(
    (key) => agreementTypeEnum[key] === warehousesSearch.type_of_agreement
  );

  const licenseType = warehousesSearch.sfda_license_type;

  const columns = [
    columnHelper.accessor('alias_name', {
      cell: ({ getValue, row }) => getValue() ?? row.original.name,
      header: t('name'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('operated_by', {
      cell: ({ getValue }) => getValue(),
      header: t('operatedBy'),
    }),
    columnHelper.accessor('address', {
      cell: ({ getValue }) => {
        const address = getValue();
        return address.city.name;
      },
      header: t('city'),
    }),
    columnHelper.accessor('open_time', {
      cell: ({ row }) => {
        const { open_time, close_time, closed_days } = row.original;

        const closeDaysTranslated = closed_days.map((day) =>
          t(`weekDays.${day}`)
        );

        const closedTime = `${open_time} - ${close_time} ${t(
          'except'
        )} ${closeDaysTranslated.join(', ')}`;

        return closedTime;
      },
      header: t('openDuration'),
    }),
    columnHelper.accessor('services_highlight', {
      cell: ({ getValue }) => `${getValue()?.max_sku_pallet} ${t('SKU')}`,
      header: t('maxSKUsPerPallet'),
    }),
    columnHelper.accessor('condition_types', {
      cell: ({ getValue }) => {
        const conditionTypes = [...new Set(getValue())];

        return (
          <HStack spacing={2}>
            {conditionTypes.map((type, index) => {
              const storageType = storageTypes[type];
              return (
                <Text as="span" key={index}>
                  {t(storageType.label)}
                </Text>
              );
            })}
          </HStack>
        );
      },
      header: t('type'),
    }),
    columnHelper.accessor('startingAt', {
      cell: ({ row }) => {
        const { starting_price } = row.original;

        return `${(starting_price ?? 0) / 100} ${t('SAR')} / ${t(
          quantityType
        )}`;
      },
      header: t('startingAt'),
    }),
    columnHelper.accessor('typeOfAgreement', {
      cell: ({ row }) => (
        <StatusTag colorScheme="blue" size="sm">
          {t(`agreementType.${typeOfAgreement}`)}
        </StatusTag>
      ),
      header: t('agreement'),
    }),
    columnHelper.accessor('licenseType', {
      cell: ({ row }) =>
        licenseType ? (
          <StatusTag colorScheme="green" size="sm">
            {t(`warehouseLicenses.${licenseType}`)}
          </StatusTag>
        ) : (
          t('noSFDALicense')
        ),
      header: t('license'),
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => (
        <ActionsMenu
          warehouse={row.original}
          onQuoteInit={onQuoteInit}
          isInitQuoteLoading={isInitQuoteLoading}
        />
      ),
      header: t('actions'),
      meta: {
        isAction: true,
      },
    }),
  ];

  return (
    <TablePage
      data={warehouseList}
      columns={columns}
      hideSearch
      hidePagination
    />
  );
};

const SuggestedWarehouses = ({
  warehouses,
  warehousesSearch = {},
  onBack,
  onSearchUpdate,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAR = language === 'ar';
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const navigate = useNavigate();

  const { mutate: initQuoteMutation, isLoading: isInitQuoteLoading } =
    useMutation(initQuote, {
      onSuccess: (data) => {
        navigate(`quote/${data.id}`, {
          state: { warehousesSearch },
        });
      },
    });

  const handleInitQuote = (warehouseId) => {
    initQuoteMutation({
      warehouseId,
      businessNeeds: warehousesSearch,
    });
  };

  const quantityType = getQuantityType(warehousesSearch);

  const quantity =
    quantityType === 'pallet'
      ? warehousesSearch.number_of_pallets
      : warehousesSearch.number_of_sqm;

  const licenseType = warehousesSearch.sfda_license_type;

  const warehouseTypeOptions = [
    {
      label: t('dry'),
      value: 'dry',
    },
    {
      label: t('tempControlled'),
      value: 'air_conditioned',
    },
    {
      label: t('chilled'),
      value: 'refrigerated',
    },
    {
      label: t('frozen'),
      value: 'frozen',
    },
  ];

  const warehouseType = warehouseTypeOptions.find(
    (warehouseType) => warehouseType.value === warehousesSearch.warehouse_type
  );

  const warehouseSearchSections = [
    {
      label: t('city'),
      value:
        (isAR
          ? warehousesSearch.city.nameLocale
          : warehousesSearch.city.name) || '-',
    },
    {
      label: t('sfdaLicensing'),
      value: t(
        licenseType ? `warehouseLicenses.${licenseType}` : 'noSFDALicense'
      ),
    },
    {
      label: t('warehouseType'),
      value: warehouseType.label || '-',
    },
    {
      label: t('quantity'),
      value: quantity ? `${quantity} ${t(quantityType)}` : '-',
    },
    {
      label: t('startDate'),
      value: formatDate(warehousesSearch.date, 'dd-MM-yyyy') ?? '-',
    },
  ];

  return (
    <Stack spacing={6}>
      <Flex>
        <BackToListButton title={t('back')} onClick={onBack} />
      </Flex>
      <Stack
        spacing={6}
        background="white"
        borderRadius="xl"
        padding={{
          md: 6,
          base: 4,
        }}
      >
        <SectionTitle title={t('allWarehouses')} hideDivider />
        <HStack
          paddingY={4}
          paddingX={6}
          borderRadius="xl"
          bg="gray.50"
          border="2px solid"
          borderColor="gray.200"
          wrap="wrap"
        >
          <HStack
            justifyContent="space-around"
            divider={<StackDivider borderColor="gray.400" borderWidth="2px" />}
            wrap={{ base: 'wrap', md: 'nowrap' }}
            spacing={2}
            rowGap={4}
            flex={1}
          >
            {warehouseSearchSections.map(({ label, value }, index) => (
              <WarehouseSearchSection label={label} value={value} key={index} />
            ))}
          </HStack>
          <Button
            leftIcon={<FilterIcon />}
            bg="white"
            color="primary.500"
            borderColor="primary.500"
            variant="outline"
            px={6}
            onClick={onSearchUpdate}
            textTransform="capitalize"
            size="lg"
            width={{ base: 'full', md: 'auto' }}
          >
            {t('editSearch')}
          </Button>
        </HStack>
        {isMobile ? (
          <SuggestedWarehousesMobile
            warehouseList={warehouses}
            warehousesSearch={warehousesSearch}
            onQuoteInit={handleInitQuote}
            isInitQuoteLoading={isInitQuoteLoading}
          />
        ) : (
          warehouses?.map((warehouse) => (
            <SuggestedWarehousesDesktopItem
              key={warehouse.id}
              warehouse={warehouse}
              warehousesSearch={warehousesSearch}
              onQuoteInit={handleInitQuote}
              isInitQuoteLoading={isInitQuoteLoading}
            />
          ))
        )}
      </Stack>
    </Stack>
  );
};

const Tab = ({ children }) => (
  <ChakraTab
    sx={{
      borderRadius: 'lg',
      paddingY: 2,
      paddingX: { base: 2, md: 5 },
      color: 'gray.600',
      fontWeight: 500,
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
    }}
    _selected={{
      color: 'primary.500',
      bg: 'primary.50',
      fontWeight: 600,
      svg: {
        path: {
          stroke: 'primary.500',
        },
      },
    }}
  >
    {children}
  </ChakraTab>
);

const getActiveTabIndex = (tabs, path) =>
  tabs.findIndex((tab) => `/warehouses${tab.path}` === path);

const WarehousesPage = () => {
  const { t } = useTranslation();
  const { pathname, state: locationState } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const defaultWarehousesSearch = locationState?.warehousesSearch;

  const [warehousesSearch, setWarehousesSearch] = useState(
    defaultWarehousesSearch
  );

  const [showRequestQuoteButton, setShowRequestQuoteButton] = useState(true);

  const onShowRequestQuoteButton = (show) => {
    setShowRequestQuoteButton(show);
  };

  const {
    mutate: requestQuoteMutation,
    isLoading: isRequestQuoteLoading,
    data: { data: suggestedWarehouses } = {},
    error,
  } = useMutation(requestQuote, {
    onSuccess: () => {
      onClose();
    },
  });

  const mapToRequestQuotePayload = (data) => {
    return {
      ...data,
      date: adjustToUTC(data.date),
      ending_date: adjustToUTC(data.ending_date),
    };
  };

  useEffect(() => {
    if (
      defaultWarehousesSearch &&
      suggestedWarehouses === undefined &&
      !isRequestQuoteLoading
    ) {
      const payload = mapToRequestQuotePayload(defaultWarehousesSearch);

      requestQuoteMutation(payload);
    }
  }, [
    defaultWarehousesSearch,
    isRequestQuoteLoading,
    requestQuoteMutation,
    suggestedWarehouses,
  ]);

  const RequestQuoteButton = ({ fullWidth }) => {
    return (
      <Button
        leftIcon={<WhiteQuotationsIcon />}
        colorScheme="primary"
        textTransform="capitalize"
        size="lg"
        width={fullWidth && 'full'}
        onClick={onOpen}
      >
        {t('requestQuote')}
      </Button>
    );
  };

  const tabs = [
    {
      title: 'warehouses',
      icon: <WarehouseIcon />,
      path: '',
    },
    {
      title: 'quotations',
      icon: <QuotationsIcon />,
      path: '/quotations',
    },
  ];

  const activeTabIndex = getActiveTabIndex(tabs, pathname);

  return (
    <>
      {isRequestQuoteLoading ? (
        <Spinner size="lg" color="primary.500" />
      ) : warehousesSearch ? (
        <SuggestedWarehouses
          warehouses={suggestedWarehouses}
          warehousesSearch={warehousesSearch}
          onBack={() => setWarehousesSearch(undefined)}
          onSearchUpdate={() => onOpen()}
        />
      ) : (
        <Stack spacing={{ base: 4, md: 6 }} height="full">
          <Tabs
            variant="unstyled"
            isLazy
            isFitted={{ base: true, md: false }}
            index={activeTabIndex}
            height="full"
          >
            <Flex
              justifyContent="space-between"
              flexFlow={{ md: 'row', base: 'column-reverse' }}
              gap={3}
              mb={4}
            >
              <TabList
                sx={{
                  background: 'white',
                  width: { base: 'full', md: 'fit-content' },
                  padding: 2,
                  borderRadius: 'xl',
                }}
              >
                {tabs.map(({ icon, title, path }, index) => (
                  <Tab key={index}>
                    <HStack spacing={2} as={Link} to={`/warehouses${path}`}>
                      {icon}
                      <Text as="span">{t(title)}</Text>
                    </HStack>
                  </Tab>
                ))}
              </TabList>
              {showRequestQuoteButton && <RequestQuoteButton />}
            </Flex>

            <Outlet
              context={{ onRequestQuote: onOpen, onShowRequestQuoteButton }}
            />
          </Tabs>
        </Stack>
      )}

      <PageDrawer
        title={t('requestQuote')}
        isOpen={isOpen}
        onClose={() => onClose()}
        formId="quoteForm"
        submitText="Search"
        isLoading={isRequestQuoteLoading}
      >
        <QuoteForm
          onSubmit={(data) => {
            const city = queryClient
              .getQueriesData(['quote-cities'])[0]?.[1]
              ?.find((quoteCity) => quoteCity.id === data.city_id);

            setWarehousesSearch({ ...data, city: city });

            const payload = mapToRequestQuotePayload(data);
            requestQuoteMutation(payload);
          }}
          backendErrors={error?.response?.data?.errors}
          defaultValues={warehousesSearch}
        />
      </PageDrawer>
    </>
  );
};

export default WarehousesPage;
