import axios from 'axios';
import { deleteToken, getToken, saveToken } from './token';
import { refreshToken } from 'api/auth';
import { deleteLocalStorage, getLocalStorage } from './localStorage';
import { getLocalStorageLanguage } from './i18n';
import { History } from 'components/NavigateSetter';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const api = axios.create({
  baseURL: API_BASE_URL,
});

api.defaults.headers.common['Content-Type'] = 'application/json';
api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// Axios request interceptor
api.interceptors.request.use(
  (config) => {
    const { token } = getToken('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.HTTP_ACCEPT_LANGUAGE = getLocalStorageLanguage();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const logoutUser = () => {
  deleteToken();
  deleteLocalStorage('user');
  History.navigate('sign-in');
};

export function setupInterceptors(updatePaymentDue) {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;

      if (!error.response) {
        return;
      }

      if (error.response.status === 402) {
        updatePaymentDue(true);
      }

      if (error.response.status === 418) {
        logoutUser();
        return;
      }

      if (
        error.response.status === 401 &&
        !originalConfig._retry &&
        originalConfig.url !== '/users/tokens'
      ) {
        const user = getLocalStorage('user');

        if (
          user &&
          !user.otp_verified_at &&
          !(
            originalConfig.url === '/users/confirm_otp' ||
            originalConfig.url === '/users/resend_otp'
          )
        ) {
          History.navigate('sign-up/verify-otp');
          return;
        }

        originalConfig._retry = true;

        try {
          const { headers } = await refreshToken();

          saveToken({
            newToken: headers['access-token'],
            expireAt: headers['expire-at'],
            refreshToken: headers['refresh-token'],
          });

          return api(originalConfig);
        } catch (error) {
          logoutUser();
        }
      }

      return Promise.reject(error);
    }
  );
}

export default api;
