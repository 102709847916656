import { QueryClient } from 'react-query';
import { createStandaloneToast } from '@chakra-ui/react';
import i18n from './i18n';
import { History } from 'components/NavigateSetter';

const { toast } = createStandaloneToast();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      cacheTime: 100,
      retry: (failureCount, error) => {
        if (error.response?.status === 403) {
          History.navigate('/');

          toast({
            title: i18n.t('notAuthorizedToTakeAction'),
            status: 'error',
            duration: 5000,
          });

          return false;
        }

        if (failureCount === 3) {
          return false;
        }

        return true;
      },
    },
  },
});
