import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { Select as ChakraReactSelect } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { inputSizes } from 'constants/theme';
import OptionalIndicator from 'components/shared/OptionalIndicator';

const Select = ({
  options,
  defaultValue,
  name,
  label,
  onChange,
  background = 'gray.50',
  size = 'md',
  error,
  required,
  isLoading,
  value,
  isMulti,
  textTransform = 'capitalize',
  expandOnOpen = false,
  placeholder,
  isDisabled,
  ...props
}) => {
  const { t } = useTranslation();

  const fontSize = ['lg', 'md'].includes(size) ? 'md' : 'sm';
  const inputHeight = inputSizes[size];

  return (
    <FormControl isInvalid={error} isRequired={!!required}>
      {label && (
        <FormLabel
          textTransform={textTransform}
          color="gray.700"
          requiredIndicator={false}
          optionalIndicator={<OptionalIndicator />}
        >
          {label}
        </FormLabel>
      )}
      <ChakraReactSelect
        name={name}
        placeholder={placeholder ?? t('selectAnOption')}
        useBasicStyles
        selectedOptionColorScheme="primary"
        variant="outline"
        onChange={onChange}
        value={value}
        isDisabled={isDisabled}
        menuPortalTarget={!expandOnOpen ? document.body : undefined}
        styles={{
          menuPortal: (baseStyles) => ({
            ...baseStyles,
            zIndex: 1401,
          }),
        }}
        chakraStyles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            background,
            fontSize,
            textTransform: 'capitalize',
            borderRadius: 'xl',
            height: inputHeight,
            _focus: {
              minHeight: inputHeight,
              height: isMulti ? 'auto' : inputHeight,
            },
            _invalid: {
              boxShadow: 'none',
              borderColor: 'red.500',
            },
          }),
          input: (baseStyles, state) => ({
            ...baseStyles,
            boxShadow: 'none !important',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            marginTop: 1,
            position: expandOnOpen ? 'relative' : undefined,
            zIndex: 1401,
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            display: 'flex',
            flexDirection: 'column',
            paddingInline: 2,
            gap: 2,
            borderRadius: 'xl',
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 'md',
            textTransform: 'capitalize',
            fontSize: 'sm',
          }),
          multiValue: (baseStyles) => ({
            ...baseStyles,
            fontSize,
            background: 'white',
            borderWidth: 1,
            borderColor: 'gray.300',
          }),
          multiValueLabel: (baseStyles) => ({
            ...baseStyles,
            color: 'gray.800',
            fontWeight: 400,
          }),
          multiValueRemove: (baseStyles) => ({
            ...baseStyles,
            color: 'gray.900',
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            height: inputHeight,
            _focus: {
              minHeight: inputHeight,
              height: isMulti ? 'auto' : inputHeight,
            },
          }),
        }}
        isSearchable={true}
        defaultValue={defaultValue}
        options={options}
        size={size}
        isLoading={isLoading}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        {...props}
      />
      <FormErrorMessage>{t(error?.message)}</FormErrorMessage>
    </FormControl>
  );
};

export default Select;
